function checkCanWithdraw() {
  if($('#v2_product_can_withdraw').is(':checked')) {
    $('#v2_product_withdrawal_fee_total').removeAttr('disabled');
    $('#v2_product_withdrawal_fee_per_payment').removeAttr('disabled');
  } else {
    $('#v2_product_withdrawal_fee_total').attr('disabled', 'disabled');
    $('#v2_product_withdrawal_fee_per_payment').attr('disabled', 'disabled');
  }
}

function checkHasSubscriptionAgreement() {
  if($('#v2_product_has_subscription_agreement').is(':checked')) {
    $('#v2_product_subscription_agreement_input, #subscription_agreement_hint_table').show();
  } else {
    $('#v2_product_subscription_agreement_input, #subscription_agreement_hint_table').hide();
  }
}

$(document).on('turbo:load', function () {
  $.each($('.product_service_areas'), function (index, el) {
    $(el).trigger('change');
  });

  $('body.panel_v2_products #v2_product_subscription_plan_ids_input ol.choices-group > li.choice').hide();
  $('body.panel_v2_products *[data-merchant-id="' + $('#v2_product_v2_merchant_id').val() + '"]').parent().parent().show();

  $('#v2_product_v2_merchant_id').on('change', function () {
    $('body.panel_v2_products #v2_product_subscription_plan_ids_input ol.choices-group > li.choice').hide();

    if ($(this).val() !== '') {
      $('body.panel_v2_products *[data-merchant-id="' + $('#v2_product_v2_merchant_id').val() + '"]').parent().parent().show();
    }
  });
});


$(document).on('change', '.product_service_areas', function () {
  var el = $(this);
  var service_area_index = el.parent().parent().parent().index('li.service_areas > fieldset')
  $.ajax({
        type: 'GET',
        url: $('#v2_product_host').val() + '/checkout/districts?city_id=' + el.children('option:selected').val(),
        success: (function (data) {
          el.parent().children('.districts').remove();
          el.parent().append('<ul class="districts"></ul>');
          $.each(data.districts, function (index, value) {
            var checked = null;

            if (el.parent().parent().children('.hidden').children('input').length) {
              var districts = el.parent().parent().children('.hidden').children('input').val().split(',');

              if (districts.includes(value)) {
                checked = 'checked="checked"';
              } else {
                checked = '';
              }
            } else {
              checked = 'checked="checked"';
            }

            el.parent().children('ul.districts').append('<li><label><input type="checkbox" ' + checked + ' name="v2_product[service_areas_attributes][' + service_area_index + '][districts][]" value="' + value + '" id="' + el.attr('id') + '_checkbox_' + index + '">' + value + '</label></li>');
          });
        })
      });
});

export default function () {
  checkCanWithdraw();
  checkHasSubscriptionAgreement();

  $('#v2_product_can_withdraw').on('click', function () {
    checkCanWithdraw();
  });

  $('#v2_product_has_subscription_agreement').on('click', function () {
    checkHasSubscriptionAgreement();
  });
}
