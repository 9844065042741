function checkAddressType() {
  if ($("#v2_subscriber_address_address_type").val() == 'shipping') {
    $('#invoice_informations').hide();
  } else {
    $('#invoice_informations').show();
  }
}

function checkInvoiceType() {
  if ($("#v2_subscriber_address_invoice_type").val() == 'personal') {
    $('#v2_subscriber_address_firm_name_input, #v2_subscriber_address_tax_office_input').hide();
    $('#v2_subscriber_address_tax_number_input .label').text('TCKN');
  } else {
    $('#v2_subscriber_address_firm_name_input, #v2_subscriber_address_tax_office_input').show();
    $('#v2_subscriber_address_tax_number_input .label').text('Vergi Numarası');
  }
}

export default function () {
  checkInvoiceType();
  checkAddressType();

  $("#v2_subscriber_address_invoice_type").on('change', function () {
    checkInvoiceType();
  });

  $("#v2_subscriber_address_address_type").on('change', function () {
    checkAddressType();
  });

  // Ülke üzerinde il seçimi
  $('#v2_subscriber_address_country').on('change', function () {
    $.ajax({
          type: 'GET',
          url: $('#v2_subscriber_address_host').val() + '/v2/regions?country_name=' + $(this).val(),
          success: (function (data) {
              $('#v2_subscriber_address_region').children('option').remove();
              $('#v2_subscriber_address_district').children('option').remove();
              $('#v2_subscriber_address_neighbourhood').children('option').remove();
              $('#v2_subscriber_address_region').prepend('<option value=""></option>');
              $.each(data.regions, function (index, value) {
                $('#v2_subscriber_address_region').append('<option value="' + value + '">' + index + ' - ' + value + '</option>');
              });
          })
        });
  });

  // İl üzerinde ilçe seçimi
  $('#v2_subscriber_address_region').on('change', function () {
    $.ajax({
          type: 'GET',
          url: $('#v2_subscriber_address_host').val() + '/v2/districts?city_id=' + $('#v2_subscriber_address_region > option:selected').text().split("-")[0].trim(),
          success: (function (data) {
              $('#v2_subscriber_address_district').children('option').remove();
              $('#v2_subscriber_address_neighbourhood').children('option').remove();
              $('#v2_subscriber_address_district').prepend('<option value=""></option>');
              $.each(data.districts, function (index, value) {
                $('#v2_subscriber_address_district').append('<option value="' + value + '">' + value + '</option>');
              });
          })
        });
  });

  // İlçe üzerinde mahalle seçimi
  $('#v2_subscriber_address_district').on('change', function () {
    $.ajax({
          type: 'GET',
          url: $('#v2_subscriber_address_host').val() + '/v2/neighbourhoods?city_id=' + $('#v2_subscriber_address_region > option:selected').text().split("-")[0].trim() + '&district=' + $(this).val(),
          success: (function (data) {
              $('#v2_subscriber_address_neighbourhood').children('option').remove();
              $('#v2_subscriber_address_neighbourhood').prepend('<option value=""></option>');
              $.each(data.neighbourhoods, function (index, value) {
                $('#v2_subscriber_address_neighbourhood').append('<option value="' + value + '">' + value + '</option>');
              });
          })
        });
  });
}
