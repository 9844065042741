export default function () {
  $('#refund_payment').click(function (e) {
    var price = $(this).data('price');
    var payment_id = $(this).data('payment-id');
    var return_url = $(this).data('return-url');
    var amount = window.prompt('İade etmek istediğiniz tutar:', price);

    if (amount !== '' && amount !== null) {
      if (amount.includes(',')) {
        alert("Lütfen virgül yerine nokta kullanın.");
      } else {
        amount = parseFloat(amount);

        if (amount < 0) {
          alert('Tutar 0\'ın altında olamaz.');
        } else if (amount > price) {
          alert('Tutar ' + price + '₺\'den fazla olamaz')
        } else {
          // Burası validasyonlardan geçen kısım
          var pattern = RegExp(/v2_subscriber_payments\/(\d+)/);

          var form = document.createElement('form');
          form.setAttribute('method', 'post');

          if ($('body.admin_namespace').length > 0) {
            form.setAttribute('action', '/admin/v2_subscriber_payments/' + payment_id + '/refund_payment');
          } else if ($('body.panel_namespace').length > 0) {
            form.setAttribute('action', '/panel/v2_subscriber_payments/' + payment_id + '/refund_payment');
          }

          var amountInput = document.createElement('input');
          amountInput.setAttribute('type', 'hidden');
          amountInput.setAttribute('name', 'amount');
          amountInput.setAttribute('value', amount);

          var returnUrlInput = document.createElement('input');
          returnUrlInput.setAttribute('type', 'hidden');
          returnUrlInput.setAttribute('name', 'return_url');
          returnUrlInput.setAttribute('value', return_url);

          var methodInput = document.createElement('input');
          methodInput.setAttribute('type', 'hidden');
          methodInput.setAttribute('name', '_method');
          methodInput.setAttribute('value', 'put');

          // Authenticity Token for Form
          var formAuthenticityTokenInput = document.getElementsByName('form_authenticity_token');
          var token = formAuthenticityTokenInput[0].value;
          var tokenInput = document.createElement('input');
          tokenInput.setAttribute('type', 'hidden');
          tokenInput.setAttribute('name', 'authenticity_token');
          tokenInput.setAttribute('value', token);

          form.appendChild(amountInput);
          form.appendChild(returnUrlInput);
          form.appendChild(methodInput);
          form.appendChild(tokenInput);

          document.body.appendChild(form);
          form.submit();
        }
      };
    }
  });
}
