import { Sortable } from "sortablejs/modular/sortable.core.esm";

export default function () {
 // Sortable
  var recompute_positions = function recompute_positions(parent) {
    parent = parent instanceof jQuery ? parent : $(this);
    var input_name = parent.data("sortable");
    var position = parseInt(parent.data("sortable-start") || 0, 10);
    parent.children("fieldset").each(function () {
      var destroy_input = $(this).find("> ol > .input > :input[name$='[_destroy]']");
      var sortable_input = $(this).find("> ol > .input > :input[name$='[" + input_name + "]']");

      if (sortable_input.length) {
        sortable_input.val(destroy_input.is(":checked") ? "" : position++);
      }
    });
  };

  var elems = $(".has_many_container[data-sortable]:not(.ui-sortable)");
  $.each(elems, function(key, el) {
    Sortable.create(el, {
      handle: '.handle',
      draggable: 'fieldset',
      onEnd: function(event) {
        elems.each(recompute_positions);
      }
    })
  });

  // elems.sortable({
  //   items: "> fieldset",
  //   handle: "> ol > .handle",
  //   start: function start(ev, ui) {
  //     ui.item.css({
  //       opacity: .3
  //     });
  //   },
  //   stop: function stop(ev, ui) {
  //     ui.item.css({
  //       opacity: 1
  //     });
  //     recompute_positions($(this));
  //   }
  // });
  elems.each(recompute_positions);
}
