let toggleButtonStatus = (el) => {
  if(el.disabled === true) {
    el.value = 'Kaldır';
    el.disabled = false;
  } else {
    el.value = 'Kaldırılıyor...';
    el.disabled = true;
  }
};

let removeFields = (element_id) => {
  $(`#${element_id}`).parents('fieldset.has_many_fields').next().remove();
  $(`#${element_id}`).parents('fieldset.has_many_fields').remove();
}

let HasManyDeleteConfirm = async () => {
  let element = $(event.currentTarget);
  toggleButtonStatus(element[0]);

  let index = element.data('index');
  let model = element.data('model')
  let productId = element.data('product-id');
  let id = $(`#${model}_attributes_${index}_id`)[0].value;
  let confirmation = await confirm('Bu işlem geri alınamaz, kaldırmak istediğinize emin misiniz?');

  if(confirmation === true) {
    let url = $('body')[0].classList.contains('panel_v2_products') ? `/panel/v2_products/${productId}/delete_item?item_id=${id}&item_model=${model}` : `/admin/v2_products/${productId}/delete_item?item_id=${id}&item_model=${model}`;
    let response = await fetch(url, { method: 'DELETE' });

    if(response.status === 200) {
      removeFields(`${model}_attributes_${index}__destroy_input`)
    } else {
      toggleButtonStatus(element[0]);
    }
  } else {
    toggleButtonStatus(element[0]);
  }
};

let AttachConfirmMessageToButtons = () => {
  $('.has_many_delete > input[type="button"]').on('click', HasManyDeleteConfirm);
};

let ChangeHasManyDeleteCheckboxToButtons = () => {
  $('.admin_v2_products .has_many_delete > input[type="hidden"], .panel_v2_products .has_many_delete > input[type="hidden"]').hide();
  $('.admin_v2_products .has_many_delete > label, .panel_v2_products .has_many_delete > label').hide();

  var new_group = '';
  var old_group = '';
  var order = 0;
  let re = /v2_products\/(\d+)/;
  let regExResult = re.exec(window.location.href);

  if(regExResult === null) {
    return;
  }

  var productId = regExResult[1];

  $('.admin_v2_products .has_many_delete, .panel_v2_products .has_many_delete').each((index, el) => {
    new_group = el.id.split('_attributes')[0];

    if(old_group == '') {
      old_group = new_group;
    }

    if(old_group !== new_group) {
      order = 0;
      old_group = '';
    }

    let newEl = $(`<input type="button" value="Kaldır" class="button bg-red-600" data-index="${order}" data-model="${new_group}" data-product-id="${productId}" />`);
    el.append(newEl[0]);

    order += 1;
  });
};

export default () => {
  ChangeHasManyDeleteCheckboxToButtons();
  AttachConfirmMessageToButtons();
};
