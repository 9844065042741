export default function () {
  $('body.panel_v2_campaigns .campaign-products > tbody > tr').hide();
  $('body.panel_v2_campaigns *[data-merchant-id="' + $('#v2_campaign_v2_merchant_id').val() + '"]').show();

  $('#v2_campaign_v2_merchant_id').on('change', function () {
    $('body.panel_v2_campaigns .campaign-products > tbody > tr').hide();

    if ($(this).val() !== '') {
      $('*[data-merchant-id="' + $(this).val() + '"]').show();
    }
  });

  $('#v2_campaign_apply_to_all_products').on('change', function () {
    if ($(this).prop('checked') === true) {
      $('body.panel_v2_campaigns .campaign-products > tbody > tr input[type="checkbox"]').prop('checked', 'checked');
    } else {
      $('body.panel_v2_campaigns .campaign-products > tbody > tr input[type="checkbox"]').prop('checked', null);
    };
  });

  $('.product-checkbox').on('change', function () {
    if ($(this).prop('checked') === true) {
      $('.plan-checkbox[data-product-id="' + $(this).data('product-id') + '"]').prop('checked', 'checked');
    } else {
      $('.plan-checkbox[data-product-id="' + $(this).data('product-id') + '"]').prop('checked', null);
    };
  });

  $('.plan-checkbox').on('change', function () {
    var checked_count = $('.plan-checkbox[data-product-id="' + $(this).data('product-id') + '"]:checked').length

    if (checked_count > 0) {
      $('.product-checkbox[data-product-id="' + $(this).data('product-id') + '"]').prop('checked', 'checked');
    } else {
      $('.product-checkbox[data-product-id="' + $(this).data('product-id') + '"]').prop('checked', null);
    }
  });
}
