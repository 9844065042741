function showDetails(type) {
  switch (type) {
    case 'dropdown':
      $('.pvo_details').removeClass('hidden');
      break;

    case 'checkbox_image':
      $('.pvo_details').removeClass('hidden');
      break;

    case 'radio_image':
      $('.pvo_details').removeClass('hidden');
      break;

    case 'spinner':
      $('.pvo_details').removeClass('hidden');
      break;

    case 'text':
      $('.pvo_details').removeClass('hidden');
      break;

    case 'button':
      $('.pvo_details').removeClass('hidden');
      break;

    case 'date':
      $('.pvo_details').removeClass('hidden');
      break;

    default:
      $('.pvo_details').addClass('hidden');
  }
}

function showValues(type) {
  switch (type) {
    case 'dropdown':
      $('.pvo_values').removeClass('hidden');
      break;

    case 'checkbox_image':
      $('.pvo_values').removeClass('hidden');
      break;

    case 'radio_image':
      $('.pvo_values').removeClass('hidden');
      break;

    case 'spinner':
      $('.pvo_values').removeClass('hidden');
      break;

    case 'button':
      $('.pvo_values').removeClass('hidden');
      break;

    default:
      $('.pvo_values').addClass('hidden');
  }
}

function showUnit(type) {
  switch (type) {
    case 'spinner':
      $('#v2_product_variant_option_unit_input').removeClass('hidden');
      break;

    default:
      $('#v2_product_variant_option_unit_input').addClass('hidden');
  }
}

function showMinimum(type) {
  switch (type) {
    case 'checkbox_image':
      $('#v2_product_variant_option_minimum_input').removeClass('hidden');
      break;

    case 'spinner':
      $('#v2_product_variant_option_minimum_input').removeClass('hidden');
      break;

    default:
      $('#v2_product_variant_option_minimum_input').addClass('hidden');
  }
}

function showMaximum(type) {
  switch (type) {
    case 'checkbox_image':
      $('#v2_product_variant_option_maximum_input').removeClass('hidden');
      break;

    case 'spinner':
      $('#v2_product_variant_option_maximum_input').removeClass('hidden');
      break;

    default:
      $('#v2_product_variant_option_maximum_input').addClass('hidden');
  }
}

function showOnlyInFirstOrder(type) {
  switch (type) {
    case 'dropdown':
      $('#v2_product_variant_option_available_only_in_first_order_input').removeClass('hidden');
      break;

    case 'checkbox_image':
      $('#v2_product_variant_option_available_only_in_first_order_input').removeClass('hidden');
      break;

    case 'radio_image':
      $('#v2_product_variant_option_available_only_in_first_order_input').removeClass('hidden');
      break;

    case 'spinner':
      $('#v2_product_variant_option_available_only_in_first_order_input').removeClass('hidden');
      break;

    case 'button':
      $('#v2_product_variant_option_available_only_in_first_order_input').removeClass('hidden');
      break;

    default:
      $('#v2_product_variant_option_available_only_in_first_order_input').addClass('hidden');
  }
}

function showMinimumInValues(type) {
  switch (type) {
    case 'spinner':
      $('.pvo_values').removeClass('hide-minimum-in-values');
      break;

    default:
      $('.pvo_values').addClass('hide-minimum-in-values');
  }
}

function showMaximumInValues(type) {
  switch (type) {
    case 'spinner':
      $('.pvo_values').removeClass('hide-maximum-in-values');
      break;

    default:
      $('.pvo_values').addClass('hide-maximum-in-values');
  }
}

function showIncreaseAmountInValues(type) {
  switch (type) {
    case 'spinner':
      $('.pvo_values').removeClass('hide-increase-amount-in-values');
      break;

    default:
      $('.pvo_values').addClass('hide-increase-amount-in-values');
  }
}

function showImageInValues(type) {
  switch (type) {
    case 'checkbox_image':
      $('.pvo_values').removeClass('hide-image-in-values');
      break;

    case 'radio_image':
      $('.pvo_values').removeClass('hide-image-in-values');
      break;

    case 'spinner':
      $('.pvo_values').removeClass('hide-image-in-values');
      break;

    default:
      $('.pvo_values').addClass('hide-image-in-values');
  }
}

function showDescriptionInValues(type) {
  switch (type) {
    case 'checkbox_image':
      $('.pvo_values').removeClass('hide-description-in-values');
      break;

    case 'radio_image':
      $('.pvo_values').removeClass('hide-description-in-values');
      break;

    case 'spinner':
      $('.pvo_values').removeClass('hide-description-in-values');
      break;

    case 'date':
      $('.pvo_values').removeClass('hide-description-in-values');
      break;

    default:
      $('.pvo_values').addClass('hide-description-in-values');
  }
}

export default function () {
  var type = $('#v2_product_variant_option_type');
  // checkOptionType(type.val());
  showValues(type.val());
  showDetails(type.val());
  showUnit(type.val());
  showMinimum(type.val());
  showMaximum(type.val());
  showMinimumInValues(type.val());
  showMaximumInValues(type.val());
  showIncreaseAmountInValues(type.val());
  showImageInValues(type.val());
  showDescriptionInValues(type.val());
  showOnlyInFirstOrder(type.val());

  type.on('change', function() {
    // checkOptionType(type.val());
    showValues(type.val());
    showDetails(type.val());
    showUnit(type.val());
    showMinimum(type.val());
    showMaximum(type.val());
    showMinimumInValues(type.val());
    showMaximumInValues(type.val());
    showIncreaseAmountInValues(type.val());
    showImageInValues(type.val());
    showDescriptionInValues(type.val());
    showOnlyInFirstOrder(type.val());
  });
}
