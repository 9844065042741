export default function () {
  $('#add_award_to_subscriber').click(function (e) {
    var amount = window.prompt('Eklemek istediğiniz tutar:');

    if (amount !== null && amount.includes(',')) {
      alert("Lütfen virgül yerine nokta kullanın.");
    } else if (amount !== null) {
      amount = parseFloat(amount);

      if (amount > 0 && amount < 100) {
        var form = document.createElement('form');
        form.setAttribute('method', 'post');

        if ($('body.admin_namespace').length > 0) {
          form.setAttribute('action', '/admin/v2_subscribers/' + window.location.href.split('/').slice(-1)[0] + '/add_award');
        } else if ($('body.panel_namespace').length > 0) {
          form.setAttribute('action', '/panel/v2_subscribers/' + window.location.href.split('/').slice(-1)[0] + '/add_award');
        }

        // Amount of Award
        var amountInput = document.createElement('input');
        amountInput.setAttribute('type', 'hidden');
        amountInput.setAttribute('name', 'amount');
        amountInput.setAttribute('value', amount);

        // Authenticity Token for Form
        var formAuthenticityTokenInput = document.getElementsByName('form_authenticity_token');
        var token = formAuthenticityTokenInput[0].value;
        var tokenInput = document.createElement('input');
        tokenInput.setAttribute('type', 'hidden');
        tokenInput.setAttribute('name', 'authenticity_token');
        tokenInput.setAttribute('value', token);

        form.appendChild(amountInput);
        form.appendChild(tokenInput);

        document.body.appendChild(form);
        form.submit();
      } else {
        alert("Lütfen 0 ve 100 arasında geçerli bir sayı girin.");
      }
    }

    e.preventDefault();
  })
}
