export default function () {
  $('#new_v2_cargo').submit(function (e) {
    $('body').prepend('<div class="loading"><p><img src="/loading.gif" style="margin:0 auto;"></p> <p><strong>Lütfen Bekleyin</strong></p></div>');
  });

  $('#new_v2_cargo a.button').click(function (e) {
    $(this).addClass('disabled');
    $('body').prepend('<div class="loading"><p><img src="/loading.gif" style="margin:0 auto;"></p> <p><strong>Lütfen Bekleyin</strong></p></div>');
  });

  $('#v2_cargo_provider').change(function (e) {
    e.preventDefault();
    $('.cargo_panels').fadeOut()
    $('#' + $(this).val() + '_panel').fadeIn()
  });

  $('#ups_barcode_count').change(function () {
    var button = $('#ups_barcode_count + .button');
    button.attr('href', button.attr('href').replace(/count=(\d+)/, 'count=' + $(this).val()));
  });
}
