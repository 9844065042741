import { Turbo, cable } from "@hotwired/turbo-rails";
// import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "trix";
import "channels";
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import "../stylesheets/active_admin.scss";
import ValueController from "../controllers/checkout/variants/value_controller";
import RegionsController from "../controllers/regions_controller";
import VariantsController from "../controllers/checkout/variants_controller";
import ValidationsController from "../controllers/validations_controller";
import FormController from "../controllers/form_controller";
import MaskController from "../controllers/mask_controller";
import '@activeadmin/activeadmin';
import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/all.css";
import Sortable from 'sortablejs/modular/sortable.core.esm.js';
import campaigns from '../activeadmin/campaigns.js';
import cargos from '../activeadmin/cargos.js';
import product_variant_options from '../activeadmin/product_variant_options.js';
import products from '../activeadmin/products.js';
import subscriber_addresses from '../activeadmin/subscriber_addresses.js';
import subscriber_payments from '../activeadmin/subscriber_payments.js';
import subscribers from '../activeadmin/subscriber.js';
import subscription_plans from '../activeadmin/subscription_plans.js';
import arctic_admin_main from '../activeadmin/arctic_admin_main.js';
import sortable from '../activeadmin/sortable.js';
import has_many_delete from '../activeadmin/has_many_delete.js';

// Rails.start();
ActiveStorage.start();
const application = Application.start();
const context = require.context("../../components", true, /_controller\.js$/);
application.load(definitionsFromContext(context));
application.register("checkout--variants--value", ValueController);
application.register("checkout--variants", VariantsController);
application.register("validations", ValidationsController);
application.register("form", FormController);
application.register("regions", RegionsController);
application.register("mask", MaskController);

const images = require.context('../images', true);

// Eski Jquery Kodları
document.addEventListener('turbo:load', campaigns)
document.addEventListener('turbo:load', cargos)
document.addEventListener('turbo:load', product_variant_options)
document.addEventListener('turbo:load', products)
document.addEventListener('turbo:load', subscriber_addresses)
document.addEventListener('turbo:load', subscriber_payments)
document.addEventListener('turbo:load', subscribers)
document.addEventListener('turbo:load', subscription_plans)
document.addEventListener('turbo:load', arctic_admin_main)
document.addEventListener('turbo:load', sortable)
document.addEventListener('turbo:load', has_many_delete)
document.addEventListener('turbo:load', () => { $('#active_admin_content .tabs').tabs() })

// TODO: Bu kodlar modüllendirilmeli
// Bannerlar, Kategoriler ve Değerler için sıralama kod parçası:
document.addEventListener('turbo:load', () => {
  $(document).trigger('mouseup', event);
  $('#advanced_options > legend span').attr('data-before', ""); // v

  $('#advanced_options > legend').on('click', () => {
    var status = $('#advanced_options > ol').css('display');

    if (status === 'none') {
      $('#advanced_options > legend span').attr('data-before', ""); // ^
      $('#advanced_options > ol').css('display', 'block');
    } else {
      $('#advanced_options > legend span').attr('data-before', ""); // v
      $('#advanced_options > ol').css('display', 'none');
    };
  });

  // Autocomplete kapatılıyor
  $('#sidebar input[type="text"]').attr('autocomplete', 'off');

  // Tabloda sıralama
  let table = null
  table = document.getElementById('index_table_v2_banners')
  if(table === null) {
    table = document.getElementById('index_table_v2_categories')
  }
  if(table === null) {
    table = document.getElementById('index_table_v2_tags')
  }

  if (table) {
    const el = table.children[1]
    const sortable = Sortable.create(el, {
      onEnd: function(event) {
        let itemIdRegEx = /_(\d+)$/
        let resourceNameRegEx = /\/([^\/]+)\/?$/
        let resourceName =  resourceNameRegEx.exec(document.location.href)[1]
        let itemId =  itemIdRegEx.exec(event.item.id)[1]

        let csrfToken = document.querySelector('meta[name="csrf-token"]').content

        fetch(`/panel/${resourceName}/${itemId}/sort`, {
          method: 'POST',
          headers: {
            'X-CSRF-TOKEN': csrfToken,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({ position: event.newIndex + 1 })
        })
      }
    })
  }
})
