function checkPlanType(type) {
  if (type === 'regular') {
    $('#regular').show();
    $('#dynamic').hide();
    $('#v2_subscription_plan_shipping_day_range_input').hide();
    $('#v2_subscription_plan_shipping_day_input').show();
  } else if (type === 'dynamic') {
    $('#regular').hide();
    $('#dynamic').show();
    $('#v2_subscription_plan_shipping_day_range_input').show();
    $('#v2_subscription_plan_shipping_day_input').hide();
  } else {
    $('#regular').hide();
    $('#dynamic').hide();
  }
}

function checkSubscriptionType(type) {
  if (type === 'for_once') {
    $('.periods').hide();
    $('.details').show();
    $('#advanced_options').show();
    $('#v2_subscription_plan_period_type_input').hide();
    $('#v2_subscription_plan_period_input').hide();
    $('#v2_subscription_plan_additional_price_input').show();
    $('#v2_subscription_plan_cycle_input').hide();
    $('#v2_subscription_plan_additional_price_type_input').show();
    $('#v2_subscription_plan_refuse_postpone_input').hide();
    // $('#v2_subscription_plan_plan_type').val('dynamic').attr('disabled', 'disabled');
    $('#v2_subscription_plan_first_time_box_input').hide();
    $('#v2_subscription_plan_first_time_box_shipping_day_input').hide();
    $('#v2_subscription_plan_plan_type').val('dynamic');
    $('#v2_subscription_plan_plan_type_input').hide();
    $('#v2_subscription_plan_shipping_day_range_input').hide();
    $('#for_once_shipping_day_range').show();
  } else if (type === 'limited') {
    $('.periods').show();
    $('.details').show();
    $('#advanced_options').show();
    $('#v2_subscription_plan_period_type_input, #v2_subscription_plan_period_input').show();
    $('#v2_subscription_plan_additional_price_input').hide();
    $('#v2_subscription_plan_cycle_input').show();
    $('#v2_subscription_plan_refuse_postpone_input').show();
    $('#v2_subscription_plan_plan_type_input').show();
    $('#v2_subscription_plan_shipping_day_range_input').show();
    $('#for_once_shipping_day_range').hide();
  } else if (type === '') {
    $('.periods').hide();
    $('.details').hide();
    $('#advanced_options').hide();
    $('#for_once_shipping_day_range').hide();
  } else {
    $('.periods').show();
    $('.details').show();
    $('#advanced_options').show();
    $('#v2_subscription_plan_period_type_input, #v2_subscription_plan_period_input').show();
    $('#v2_subscription_plan_additional_price_input').hide();
    $('#v2_subscription_plan_cycle_input').hide();
    $('#v2_subscription_plan_additional_price_type_input').hide();
    // $('#v2_subscription_plan_plan_type').removeAttr('disabled');
    $('#v2_subscription_plan_first_time_box_input').show();
    $('#v2_subscription_plan_first_time_box_shipping_day_input').show();
    $('#v2_subscription_plan_refuse_postpone_input').show();
    $('#v2_subscription_plan_plan_type_input').show();
    $('#v2_subscription_plan_shipping_day_range_input').show();
    $('#for_once_shipping_day_range').hide();
  }
}

function checkFirstTimeBox(checked) {
  if (checked) {
    $('#v2_subscription_plan_first_time_box_shipping_day').removeAttr('disabled');
  } else {
    $('#v2_subscription_plan_first_time_box_shipping_day').attr('disabled', 'disabled');
  }
}

function getTextByPlanTypeAndPeriodType(plan_type, period_type, period) {
  var message = '';
  var period_text = period === '' ? '_' : period;

  if (plan_type === 'turnpaid') {
    message = '1 ödeme, 1 sipariş';
  } else if (plan_type === 'limited') {
    message = '1 ödeme, 1 sipariş';
  } else if (plan_type === 'prepaid') {
    if (period_type === 'monthly') {
      message = `1 ödeme, her ay 1 sipariş, toplamda ${period_text} sipariş`;
    } else if (period_type === 'weekly') {
      message = `1 ödeme, her hafta 1 sipariş, toplamda ${period_text} sipariş`;
    } else if (period_type === 'biweekly') {
      message = `1 ödeme, her 2 haftada 1 sipariş, toplamda ${period_text} sipariş`;
    }
  }

  if ($('#sentence_end').length > 0) {
    $('#sentence_end')[0].innerText = message;
  }
}

function triggerPlanText() {
  let period = $('#v2_subscription_plan_period').val();
  let period_type = $('#v2_subscription_plan_period_type').val();
  let plan_type = $('#v2_subscription_plan_subscription_type').val();

  getTextByPlanTypeAndPeriodType(plan_type, period_type, period);
}

// function copyShippingDay(val) {
//   $('#v2_subscription_plan_shipping_day').val(val);
//   $('#v2_subscription_plan_shipping_day_range').val(val);
// }

function syncShippingDayRangeForOnce() {
  let exists = $('#v2_subscription_plan_add_shipping_day_range_to_checkout_date_false').length + $('#v2_subscription_plan_add_shipping_day_range_to_checkout_date_true').length;

  if(exists === 0) {
    return;
  }

  if($('#v2_subscription_plan_add_shipping_day_range_to_checkout_date_false')[0].checked === true) {
    $('.v2_subscription_plan_shipping_day_range_false_reflection').val($('#v2_subscription_plan_shipping_day_range').val());
  }

  if($('#v2_subscription_plan_add_shipping_day_range_to_checkout_date_true')[0].checked === true) {
    $('.v2_subscription_plan_shipping_day_range_true_reflection').val($('#v2_subscription_plan_shipping_day_range').val());
  }

  $('#v2_subscription_plan_period_type_reflection')[0].value = $('#v2_subscription_plan_period_type')[0].value;
}

export default function () {
  checkPlanType($('#v2_subscription_plan_plan_type').val());
  checkSubscriptionType($('#v2_subscription_plan_subscription_type').val());
  checkFirstTimeBox($('#v2_subscription_plan_first_time_box').prop('checked'));
  syncShippingDayRangeForOnce();
  triggerPlanText();

  $('#v2_subscription_plan_subscription_type').on('change', function () {
    checkPlanType($('#v2_subscription_plan_plan_type').val());
    checkSubscriptionType($(this).val());
    triggerPlanText();
  });

  $('#v2_subscription_plan_plan_type').on('change', function () {
    checkPlanType($(this).val());
  });

  $('#v2_subscription_plan_first_time_box').on('click', function () {
    checkFirstTimeBox($(this).prop('checked'))
  });

  $('#v2_subscription_plan_period').keyup(function () {
    triggerPlanText();
  });

  $('#v2_subscription_plan_period_type').on('change', function () {
    triggerPlanText();
  });

  $('#v2_subscription_plan_period_type_reflection').on('change', function() {
    $('#v2_subscription_plan_period_type')[0].value = $(this)[0].value;
  });

  $('.v2_subscription_plan_shipping_day_range_true_reflection, .v2_subscription_plan_shipping_day_range_false_reflection').on('keyup', function() {
    if($('#v2_subscription_plan_add_shipping_day_range_to_checkout_date_false')[0].checked === true) {
      $('#v2_subscription_plan_shipping_day_range').val($('.v2_subscription_plan_shipping_day_range_false_reflection').val());
    }

    if($('#v2_subscription_plan_add_shipping_day_range_to_checkout_date_true')[0].checked === true) {
      $('#v2_subscription_plan_shipping_day_range').val($('.v2_subscription_plan_shipping_day_range_true_reflection').val());
    }
  });

  $('#v2_subscription_plan_add_shipping_day_range_to_checkout_date_false, #v2_subscription_plan_add_shipping_day_range_to_checkout_date_true').on('click', function() {
    if($('#v2_subscription_plan_add_shipping_day_range_to_checkout_date_false')[0].checked === true) {
      $('#v2_subscription_plan_shipping_day_range').val($('.v2_subscription_plan_shipping_day_range_false_reflection').val());
    }

    if($('#v2_subscription_plan_add_shipping_day_range_to_checkout_date_true')[0].checked === true) {
      $('#v2_subscription_plan_shipping_day_range').val($('.v2_subscription_plan_shipping_day_range_true_reflection').val());
    }
  });

  // $('#v2_subscription_plan_shipping_day_range').on('change', function() {
  //   copyShippingDay($(this).val());
  // });

  // $('#v2_subscription_plan_shipping_day').on('change', function() {
  //   copyShippingDay($(this).val());
  // });
}
